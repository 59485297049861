.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 230px;
  z-index: 100000;
  width: 230px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out; }

.sidebar-shrink {
  width: 50px !important; }
  .sidebar-shrink .sidebar-menu {
    overflow: unset; }
  .sidebar-shrink li > a > span {
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
    top: 0;
    left: 45px;
    height: 100%;
    background: #d4d4d4;
    border-radius: 0 5px 5px 0;
    padding: 9px;
    opacity: 0;
    transition: all 0.1s; }
  .sidebar-shrink li a.active {
    padding: 12px 5px 12px 14px !important; }
  .sidebar-shrink li a {
    display: flex !important;
    align-content: center; }
    .sidebar-shrink li a:hover {
      background-color: #d4d4d4; }
      .sidebar-shrink li a:hover span {
        opacity: 1;
        visibility: visible; }
  .sidebar-shrink li .active:hover span {
    background-color: #978ef5; }

.sidebar {
  padding-bottom: 10px; }

.sidebar-form input:focus {
  border-color: transparent; }

.menu-open > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden; }
  .user-panel > .image > img {
    width: 100%;
    max-width: 45px;
    height: auto; }
  .user-panel .logo-lg {
    display: flex;
    justify-content: center; }
  .user-panel > .info {
    color: #ffffff;
    padding: 17px 5px 5px 15px;
    line-height: 1;
    position: absolute;
    left: 55px; }
    .user-panel > .info > p {
      font-weight: 600;
      margin-bottom: 9px; }
    .user-panel > .info > a {
      color: #ffffff;
      text-decoration: none;
      padding-right: 5px;
      margin-top: 3px;
      font-size: 11px; }
      .user-panel > .info > a > .fa,
      .user-panel > .info > a > .ion,
      .user-panel > .info > a > .glyphicon {
        margin-right: 3px; }

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 80vh;
  overflow-y: auto;
  border-top: 1px solid #8080805e;
  border-bottom: 1px solid #8080805e; }
  .sidebar-menu.logout {
    height: auto;
    border: none; }
  .sidebar-menu > li {
    position: relative;
    margin: 0;
    padding: 0; }
    .sidebar-menu > li > a {
      padding: 12px 5px 12px 15px;
      display: block;
      color: black;
      transition: all 0.2s; }
      .sidebar-menu > li > a > .fa,
      .sidebar-menu > li > a > .glyphicon,
      .sidebar-menu > li > a > .ion {
        width: 20px; }
      .sidebar-menu > li > a:hover {
        padding: 12px 0px 12px 20px; }
    .sidebar-menu > li > a.active {
      background-image: linear-gradient(to right, #7367f0, #9a91f3);
      width: 100%;
      color: white;
      padding: 12px 5px 12px 8px; }
    .sidebar-menu > li .label,
    .sidebar-menu > li .badge {
      margin-right: 5px; }
    .sidebar-menu > li .badge {
      margin-top: 3px; }
  .sidebar-menu li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px; }
  .sidebar-menu li > a > .fa {
    width: 20px; }
  .sidebar-menu li > a > .fa-angle-left,
  .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px; }
  .sidebar-menu li > a > .fa-angle-left {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -8px; }
  .sidebar-menu li > a > .pull-right-container {
    right: 10px;
    top: 50%;
    margin-top: -7px; }
  .sidebar-menu .active > .treeview-menu {
    display: block; }

.sidebar-menu.logout {
  position: absolute;
  width: 100%;
  bottom: 16px; }

.treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px; }
  .treeview-menu .treeview-menu {
    padding-left: 20px; }
  .treeview-menu > li {
    margin: 0; }
    .treeview-menu > li > a {
      padding: 5px 5px 5px 15px;
      display: block;
      font-size: 14px; }
      .treeview-menu > li > a > .fa,
      .treeview-menu > li > a > .glyphicon,
      .treeview-menu > li > a > .ion {
        width: 20px; }
      .treeview-menu > li > a > .pull-right-container > .fa-angle-left,
      .treeview-menu > li > a > .pull-right-container > .fa-angle-down,
      .treeview-menu > li > a > .fa-angle-left,
      .treeview-menu > li > a > .fa-angle-down {
        width: auto; }

.sidebar-collapse .sidebar-menu > li > a > span {
  display: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.sidebar-collapse .sidebar-menu > li:hover > a > span {
  top: 0;
  padding: 12px 5px 12px 20px;
  background-color: inherit; }

.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
  position: relative !important;
  float: right;
  width: auto !important;
  left: 180px !important;
  top: -22px !important;
  z-index: 900; }

.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right) {
  display: block !important;
  position: absolute;
  width: 180px;
  left: 50px; }

.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
  top: 44px;
  margin-left: 0;
  display: block !important;
  position: absolute;
  width: 180px;
  left: 50px; }

.sidebar-collapse .user-panel > .info {
  display: none; }

.sidebar-collapse .main-sidebar {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 50px !important;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  z-index: 850; }

.sidebar-collapse .user-panel {
  padding: 10px 7px 10px 14px; }

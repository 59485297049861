/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
  height: 100%; }
  .layout-boxed html, .layout-boxed
  body {
    height: 100%; }

body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-y: auto; }

/* Layout */
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto; }
  .layout-boxed .wrapper {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: relative; }

.layout-boxed {
  background-color: #f9fafc; }

/*
  * Content Wrapper - contains the main content
  */
.content-wrapper,
.main-footer {
  margin-left: 230px;
  z-index: 820; }
  .layout-top-nav .content-wrapper, .layout-top-nav
  .main-footer {
    margin-left: 0; }

.content-wrapper {
  min-height: 100%;
  z-index: 800; }

.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de; }

/* Fixed layout */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed; }

.fixed .main-header {
  top: 0;
  right: 0;
  left: 0; }

.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px; }
  @media (max-width: 992px) {
    .fixed .content-wrapper,
    .fixed .right-side {
      padding-top: 100px; } }

.fixed.layout-boxed .wrapper {
  max-width: 100%; }

.fixed .wrapper {
  overflow: hidden; }

/* Content */
.content {
  min-height: 250px;
  padding: 15px; }

/* General Links */
a {
  color: #3c8dbc; }

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #72afd2; }

/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px; }
  .page-header > small {
    color: #666;
    display: block;
    margin-top: 5px; }

.skin-blue .main-header .logo {
  color: #fff;
  border-bottom: 0 solid transparent; }

.skin-blue .main-header .navbar {
  background-color: #3c8dbc; }

.skin-blue .main-sidebar {
  background-color: white; }

.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-blue .treeview.menu-open > a {
  border-left: 3px solid #3c8dbc; }

.skin-blue .sidebar-menu .treeview-menu > li > a {
  color: #8aa4af; }

.sidebar-collapse .content-wrapper {
  margin-left: 50px; }

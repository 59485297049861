.login-container {
  font-family: brandon-bold;
  margin-top: -50px; }

.login-inner {
  width: 25%;
  text-align: center;
  margin-bottom: 50px; }
  .login-inner img {
    margin: 20px 0; }

.login-box-body {
  background: #fff;
  padding: 0 20px 25px;
  border-top: 0;
  color: #666;
  border: 1px solid #d4d2d2; }
  .login-box-body h3 {
    text-align: center; }
  .login-box-body p {
    margin-bottom: 0px;
    text-align: center;
    padding: 20px 0 20px 0;
    font-size: 18px; }
  .login-box-body .form-control {
    border-radius: 0px; }

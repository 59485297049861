.navbar {
  margin-bottom: 0 !important;
  padding: 5px;
  box-shadow: 0 4px 20px 0 rgba(114, 114, 114, 0.7);
  background: white !important;
  padding: 0 !important; }
  .navbar a {
    color: #626262 !important; }
  .navbar li a {
    padding: 10px; }
  .navbar li .active {
    background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
    background-image: -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dbdbdb), to(#e2e2e2));
    background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075); }

/*
 * Component: Main Header
 * ----------------------
 */
.header-expand {
  width: calc(100% - 50px) !important; }

.main-header {
  position: fixed;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  max-height: 100px;
  z-index: 1030;
  width: calc(100% - 230px);
  padding: 20px;
  padding-bottom: 0; }
  .main-header .navbar::before,
  .main-header .navbar::after {
    content: none !important; }
  .main-header .navbar {
    border: none;
    min-height: 50px;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out; }
    .main-header .navbar .layout-top-nav {
      margin-left: 0; }
    .main-header .navbar .search-sidebar-toggle {
      display: flex;
      align-items: center; }
      .main-header .navbar .search-sidebar-toggle input {
        height: 30px;
        transition: all 0.2s;
        font-size: 14px;
        padding: 0 5px;
        border-radius: 5px;
        border: 1px solid gray;
        outline: none; }
        .main-header .navbar .search-sidebar-toggle input:focus {
          outline: none;
          border: 1px solid #7367f0;
          box-shadow: 0 0 5px #afafaf; }
        .main-header .navbar .search-sidebar-toggle input::-ms-input-placeholder {
          font-size: 14px;
          border: 1px solid #7367f0; }
        .main-header .navbar .search-sidebar-toggle input:active {
          border: none;
          outline: none; }
  .main-header #navbar-search-input.form-control {
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent; }
    .main-header #navbar-search-input.form-control:focus, .main-header #navbar-search-input.form-control:active {
      border-color: rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.9); }
    .main-header #navbar-search-input.form-control::-moz-placeholder {
      color: #ccc;
      opacity: 1; }
    .main-header #navbar-search-input.form-control:-ms-input-placeholder {
      color: #ccc; }
    .main-header #navbar-search-input.form-control::-webkit-input-placeholder {
      color: #ccc; }
  .main-header .navbar-custom-menu,
  .main-header .navbar-right {
    float: right; }
    @media (max-width: 992px) {
      .main-header .navbar-custom-menu a,
      .main-header .navbar-right a {
        color: inherit;
        background: transparent; } }
  @media (max-width: 992px) {
    .main-header .navbar-right {
      float: none; }
      .navbar-collapse .main-header .navbar-right {
        margin: 7.5px -15px; }
      .main-header .navbar-right > li {
        color: inherit;
        border: 0; } }
  .main-header .sidebar-toggle {
    color: #ffffff;
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px 15px;
    font-family: fontAwesome; }
    .main-header .sidebar-toggle:before {
      content: '\f0c9'; }
    .main-header .sidebar-toggle:hover {
      color: #fff; }
    .main-header .sidebar-toggle:focus, .main-header .sidebar-toggle:active {
      background: transparent; }
  .main-header .sidebar-toggle .icon-bar {
    display: none; }
  .main-header .navbar .nav > li.user > a > .fa,
  .main-header .navbar .nav > li.user > a > .glyphicon,
  .main-header .navbar .nav > li.user > a > .ion {
    margin-right: 5px; }
  .main-header .navbar .nav > li > a > .label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: 0.9; }
  .main-header .logo {
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 230px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out; }
    .main-header .logo img {
      width: 142px;
      margin-bottom: 5px; }
    .main-header .logo .logo-lg {
      display: block; }
    .main-header .logo .logo-mini {
      display: none; }
  .main-header .navbar-brand {
    color: #fff; }

.content-header {
  position: relative;
  padding: 15px 15px 0 15px; }
  .content-header > h1 {
    margin: 0;
    font-size: 24px; }
    .content-header > h1 > small {
      font-size: 15px;
      display: inline-block;
      padding-left: 4px;
      font-weight: 300; }
  .content-header > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px; }
    .content-header > .breadcrumb > li > a {
      color: #444;
      text-decoration: none;
      display: inline-block; }
      .content-header > .breadcrumb > li > a > .fa,
      .content-header > .breadcrumb > li > a > .glyphicon,
      .content-header > .breadcrumb > li > a > .ion {
        margin-right: 5px; }
    .content-header > .breadcrumb > li + li:before {
      content: '>\00a0'; }
  @media (max-width: 992px) {
    .content-header > .breadcrumb {
      position: relative;
      margin-top: 5px;
      top: 0;
      right: 0;
      float: none;
      background: #d2d6de;
      padding-left: 10px; }
      .content-header > .breadcrumb li:before {
        color: #97a0b3; } }

.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0; }

@media (max-width: 992px) {
  .navbar-custom-menu .navbar-nav > li {
    float: left; }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left; }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px; } }

@media (max-width: 992px) {
  .main-header {
    position: relative; }
    .main-header .logo,
    .main-header .navbar {
      width: 100%;
      float: none; }
    .main-header .navbar {
      margin: 0; }
    .main-header .navbar-custom-menu {
      float: right; } }

@media (max-width: 992px) {
  .navbar-collapse.pull-left {
    float: none !important; }
    .navbar-collapse.pull-left + .navbar-custom-menu {
      display: block;
      position: absolute;
      top: 0;
      right: 40px; } }

.user-menu a {
  color: #ffffff; }
  .user-menu a:hover {
    background-color: #80808038 !important; }

.user-menu .user-image {
  width: 30px;
  margin-right: 5px; }

.user-menu .user-name {
  color: #626262; }

.sidebar-collapse .main-header .logo > .logo-lg {
  display: none; }

.sidebar-collapse .main-header .logo {
  width: 50px !important;
  z-index: 850; }

.sidebar-collapse .main-header .logo-mini {
  display: block; }
  .sidebar-collapse .main-header .logo-mini img {
    width: 25px; }

.sidebar-collapse .main-header .navbar {
  margin-bottom: 0;
  margin-left: 50px; }

.sidebar-collapse .sidebar-menu > li > a {
  padding: 12px 5px 12px 20px; }

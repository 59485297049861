#users-list .user-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  #users-list .user-buttons select {
    padding: 6px;
    margin: 0 10px; }

#users-list .list .user-row .userImg {
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%; }

#users-list .list .user-row .super {
  color: red; }

#users-list .list .user-row .sub {
  color: orange; }

#users-list .list-u th {
  cursor: pointer;
  border: none; }
  #users-list .list-u th i.fas {
    margin-left: 10px; }
    #users-list .list-u th i.fas.disabled {
      color: #a1a1a1;
      background-color: transparent !important; }

#users-list .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 22px 0;
  border-radius: 4px; }

#users-list .pagination > li {
  display: inline; }

#users-list .pagination > li > a,
#users-list .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px; }

#users-list .pagination > li:first-child > a,
#users-list .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; }

#users-list .pagination > li:last-child > a,
#users-list .pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

#users-list .pagination > li > a:hover,
#users-list .pagination > li > span:hover,
#users-list .pagination > li > a:focus,
#users-list .pagination > li > span:focus {
  z-index: 2;
  color: #1b4060;
  background-color: #eeeeee;
  border-color: #ddd; }

#users-list .pagination > .active > a,
#users-list .pagination > .active > span,
#users-list .pagination > .active > a:hover,
#users-list .pagination > .active > span:hover,
#users-list .pagination > .active > a:focus,
#users-list .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #2c689c;
  border-color: #2c689c;
  cursor: default; }

#users-list .pagination > .disabled > span,
#users-list .pagination > .disabled > span:hover,
#users-list .pagination > .disabled > span:focus,
#users-list .pagination > .disabled > a,
#users-list .pagination > .disabled > a:hover,
#users-list .pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed; }

#users-list .pagination-lg > li > a,
#users-list .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333; }

#users-list .pagination-lg > li:first-child > a,
#users-list .pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

#users-list .pagination-lg > li:last-child > a,
#users-list .pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

#users-list .pagination-sm > li > a,
#users-list .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5; }

#users-list .pagination-sm > li:first-child > a,
#users-list .pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

#users-list .pagination-sm > li:last-child > a,
#users-list .pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.subs_check {
  display: flex; }
  .subs_check input[type='checkbox'] {
    margin-right: 10px; }

#user .singleUser {
  margin-top: 16px; }
  #user .singleUser .no-orders {
    margin: 10px; }
  #user .singleUser .userInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    #user .singleUser .userInfo .profilePic {
      margin: 20px;
      width: 120px;
      height: 120px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%; }
    #user .singleUser .userInfo .userData {
      margin: 10px; }
      #user .singleUser .userInfo .userData .table {
        height: 100%;
        border: 0; }
      #user .singleUser .userInfo .userData th,
      #user .singleUser .userInfo .userData td {
        margin: 10px; }
      #user .singleUser .userInfo .userData th {
        min-width: 100px; }
    #user .singleUser .userInfo .reset-password {
      border: 1px solid silver;
      padding: 0 20px;
      border-radius: 8px; }
      #user .singleUser .userInfo .reset-password i {
        margin-left: 10px;
        cursor: pointer; }
      #user .singleUser .userInfo .reset-password .btns {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        #user .singleUser .userInfo .reset-password .btns button {
          margin-left: 0;
          margin-right: 0; }
          #user .singleUser .userInfo .reset-password .btns button:disabled {
            opacity: 0.6; }
        #user .singleUser .userInfo .reset-password .btns .def-pass {
          color: #1b4060;
          text-decoration: underline;
          margin-right: 50px;
          cursor: pointer; }
      #user .singleUser .userInfo .reset-password .comp {
        font-size: 11px; }
  #user .singleUser ul {
    padding-inline-start: 20px !important; }

#create-user .match-valid {
  color: green; }

#create-user .match-invalid {
  color: red; }

.artist-create-container .create-artist .row .form-control,
.artist-create-container .create-member .row .form-control {
  margin-bottom: 15px; }

.artist-create-container .create-artist .row .btn-upload,
.artist-create-container .create-member .row .btn-upload {
  margin-bottom: 15px; }

.artist-create-container .create-artist .row .include-box,
.artist-create-container .create-member .row .include-box {
  display: flex; }
  .artist-create-container .create-artist .row .include-box span,
  .artist-create-container .create-member .row .include-box span {
    font-size: 26px;
    font-weight: bold;
    cursor: pointer; }

.artist-create-container .create-artist #uploadAudioEdit button,
.artist-create-container .create-member #uploadAudioEdit button {
  right: 0; }

.artist-create-container .member-list {
  position: relative; }
  .artist-create-container .member-list hr {
    border-top: 1px solid #c7c6c6; }
  .artist-create-container .member-list .remove {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 20px;
    color: #c70606; }

.create-subscription {
  display: flex; }
  .create-subscription .notice {
    color: #d40d0d;
    margin: 10px; }
  .create-subscription .preview-card {
    position: fixed;
    height: 88vh;
    overflow-y: scroll; }
    .create-subscription .preview-card .prev {
      background-color: rgba(219, 219, 219, 0.993);
      padding: 5px;
      border-radius: 8px; }
    .create-subscription .preview-card .preview-text {
      margin: 5px 20px;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      align-items: center; }
    .create-subscription .preview-card .add-colors {
      margin: 5px 20px;
      color: #54a0e7;
      text-decoration: underline;
      cursor: pointer; }
  .create-subscription .card {
    position: relative;
    width: 500px;
    border-radius: 4px;
    background: #dadada;
    padding: 30px 30px 10px 30px;
    margin: 20px;
    overflow: hidden; }
    .create-subscription .card > div {
      position: relative; }
    .create-subscription .card .back-circle {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 190px;
      height: 190px;
      z-index: 0;
      border-radius: 50%;
      background: linear-gradient(to bottom right, #d4d4d4, #d4d4d4); }
    .create-subscription .card .t1 {
      color: black;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 10px; }
    .create-subscription .card .cost .price {
      font-size: 49px; }
    .create-subscription .card .cost .dur {
      font-size: 25px; }
    .create-subscription .card .t2 {
      padding-inline-start: 16px !important;
      font-size: 18px; }
    .create-subscription .card .t3 {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between; }
      .create-subscription .card .t3 .button {
        width: 130px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 2px;
        background: #757575;
        color: white; }
      .create-subscription .card .t3 .compare {
        color: #444444;
        font-size: 14px; }

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/montserrat/Montserrat-Regular.otf);
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

.search-cont {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.row.category select {
  padding: 5px;
  margin-bottom: 10px;
  width: 100%; }

.piece-filters select {
  margin: 10px;
  padding: 5px; }

.currentFile {
  margin: 10px 0; }

.create-piece {
  width: 40%;
  margin: auto; }
  .create-piece label {
    text-align: center; }
  .create-piece .col-md-3,
  .create-piece .col-md-9 {
    margin-bottom: 15px; }

.add-more .btn {
  margin-right: 15px; }

.create-instrument hr {
  border-top: 1px solid #cac7c7; }

.upload-btn {
  margin: 15px 0; }

.instrument-list {
  position: relative; }
  .instrument-list .remove {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 20px;
    color: #c70606; }

.navBarPiece {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap; }

.navBarField {
  width: 16.6%;
  height: 100%;
  display: inline;
  text-align: center;
  cursor: pointer; }

.navBarFieldActive {
  background-color: #9aa0be;
  height: 50px; }

.navBarHeading {
  margin-top: 15px;
  font-size: 20px; }

.submitButton {
  position: relative;
  margin-top: 20px; }

.RichTextEditor__root___2QXK- {
  margin-bottom: 20px; }

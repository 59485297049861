#tabs-component {
  width: 95%;
  margin: 10px auto; }
  #tabs-component .titles {
    display: flex;
    background-color: white; }
    #tabs-component .titles > div {
      width: 100%;
      font-size: 20px;
      text-transform: uppercase;
      color: black;
      border-bottom: 2px solid transparent;
      text-align: center;
      padding: 10px;
      cursor: pointer; }
      #tabs-component .titles > div.selected {
        border-bottom: 2px solid #106ddf;
        color: #106ddf; }
  #tabs-component .components {
    background-color: white;
    border-top: 1px solid gray; }
    #tabs-component .components > div {
      margin: 0 auto;
      padding: 10px; }

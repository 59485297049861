@font-face {
  font-family: 'brandon-bold';
  src: url("./assets/fonts/brandon-grotesque/Brandon_bld.otf"); }

@font-face {
  font-family: 'brandon-medium';
  src: url("./assets/fonts/brandon-grotesque/Brandon_med.otf"); }

@font-face {
  font-family: 'brandon-regular';
  src: url("./assets/fonts/brandon-grotesque/Brandon_reg.otf"); }

@font-face {
  font-family: 'brandon-premier';
  src: url("./assets/fonts/garamond-premier-pro/GaramondPremrPro.otf"); }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(./assets/fonts/montserrat/Montserrat-SemiBold.otf); }

.disabled {
  pointer-events: none; }

body {
  background: #f5f5f5; }

.disabled {
  pointer-events: none;
  background-color: #c4c4c4; }

.loader {
  left: 50%;
  top: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .loader #spinner {
    box-sizing: border-box;
    stroke: #673ab7;
    stroke-width: 3px;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
    animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite; }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg); } }

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0; }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); } }

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0; }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); } }

.spinner {
  top: 5px;
  position: relative; }

.spinner:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner 0.6s linear infinite; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.d-flex {
  display: flex; }

.d-flex-col {
  display: flex;
  flex-direction: column; }

.d-flex-row {
  display: flex;
  flex-direction: row; }

.fm-center {
  justify-content: center; }

.fc-center {
  align-items: center; }

.fmc-center {
  justify-content: center;
  align-items: center; }

.screen-height {
  height: 100vh; }

.btn-flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-width: 1px; }

.error {
  text-align: left;
  color: red; }

.box {
  position: relative;
  border-radius: 3px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  .box .box-header {
    color: #444;
    display: block;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .box .box-header .box-title {
      display: inline-block;
      font-size: 24px;
      font-family: Montserrat-SemiBold;
      margin: 0;
      line-height: 1; }
  .box .box-body {
    background: white;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
    overflow-x: auto;
    animation-name: scaleFadeIn;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-duration: 0.5ms;
    animation-fill-mode: forwards; }
  .box .with-border {
    border-bottom: 1px solid #f4f4f4; }

#pieces table .loader {
  top: auto !important;
  transform: translate(-50%) !important; }

#pieces table .singleDetailRow td label {
  margin-right: 5px; }

#pieces table .singleDetailRow td > div {
  padding: 10px 0; }
  #pieces table .singleDetailRow td > div:not(:nth-last-child(1)) {
    border-bottom: 2px solid #c8c8c8; }

.table {
  border: 1px solid #e3e3e3; }
  .table .t-10 {
    width: 10%; }
  .table .t-15 {
    width: 15%; }
  .table .t-20 {
    width: 20%; }
  .table .t-25 {
    width: 25%; }
  .table .t-30 {
    width: 30%; }
  .table .t-35 {
    width: 34%; }
  .table .t-40 {
    width: 40%; }
  .table .t-50 {
    width: 50%; }
  .table th {
    font-family: Montserrat-SemiBold; }
  .table tbody {
    animation-name: scaleFadeIn;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-duration: 0.5ms;
    animation-fill-mode: forwards;
    -webkit-animation-name: scaleFadeIn !important;
    -webkit-animation-timing-function: ease-in-out !important;
    -webkit-animation-delay: 0 !important;
    -webkit-animation-duration: 0.5ms !important;
    -webkit-animation-fill-mode: forwards !important; }
    .table tbody tr:nth-child(odd) {
      background: rgba(217, 217, 217, 0.451); }
    .table tbody tr:nth-child(even) {
      background: rgba(240, 240, 240, 0.171); }
    .table tbody tr td input[type='checkbox'] {
      width: 18px;
      height: 18px;
      cursor: pointer; }
    .table tbody td .subs-check {
      display: flex; }
      .table tbody td .subs-check input[type='checkbox'] {
        min-width: 16px;
        height: 16px;
        margin-right: 10px; }
    .table tbody td .save {
      font-size: 20px;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px; }

.form-control {
  border-radius: 0px !important;
  border-color: #d2d6de; }

.well {
  border-radius: 2px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  .well .instruction {
    font-weight: bold;
    color: #f1235c;
    margin: 0;
    border: 2px solid #8a8a8a36;
    padding: 10px;
    display: inline-block;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .well .radio-part {
    margin: 10px 0; }
    .well .radio-part .radio-selection input[type='radio'],
    .well .radio-part .radio-selection label {
      cursor: pointer;
      outline: none; }
  .well .editButtons {
    display: flex;
    justify-content: flex-end; }

.btn {
  border-radius: 0px; }

.hide {
  display: none; }

.cus-btn-primary {
  padding: 0.75rem 2rem;
  background: #7367f0;
  margin: 1rem;
  border-radius: 5px;
  color: white;
  border: none; }
  .cus-btn-primary:hover {
    color: white !important; }

.cus-btn-danger {
  padding: 0.75rem 2rem;
  background: #ea5455;
  margin: 1rem;
  border-radius: 5px;
  color: white;
  border: none; }
  .cus-btn-danger:hover {
    color: white !important; }

.cus-btn-success {
  padding: 0.75rem 2rem;
  background: #ea5455;
  margin: 1rem;
  border-radius: 5px;
  color: white;
  border: none; }
  .cus-btn-success:hover {
    color: white !important; }

.cus-btn-warning {
  padding: 0.75rem 2rem;
  background: #ff9f43;
  margin: 1rem;
  border-radius: 5px;
  color: white;
  border: none; }
  .cus-btn-warning:hover {
    color: white !important; }

.top-container {
  height: 100vh; }
  .top-container .fixedElements {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 230px;
    transition: all 0.3s; }
    .top-container .fixedElements .content-wrapper {
      margin-left: 0;
      padding: 20px;
      margin-top: 60px;
      margin-left: 0 !important; }
  .top-container .expandView {
    margin-left: 50px !important; }

.loginScreen {
  margin-left: 0 !important; }
  .loginScreen .content-wrapper {
    margin-top: 0 !important; }

.scaleFadeIn {
  animation-name: scaleFadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.5ms;
  animation-fill-mode: forwards;
  -webkit-animation-name: scaleFadeIn !important;
  -webkit-animation-timing-function: ease-in-out !important;
  -webkit-animation-delay: 0 !important;
  -webkit-animation-duration: 0.5ms !important;
  -webkit-animation-fill-mode: forwards !important; }

.scaleFadeOut {
  animation-name: scaleFadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-duration: 0.5ms;
  animation-fill-mode: forwards;
  -webkit-animation-name: scaleFadeIn !important;
  -webkit-animation-timing-function: ease-in-out !important;
  -webkit-animation-delay: 0 !important;
  -webkit-animation-duration: 0.5ms !important;
  -webkit-animation-fill-mode: forwards !important; }

@keyframes scaleFadeOut {
  from {
    transform: scale(1);
    opacity: 1; }
  to {
    transform: scale(1.02);
    opacity: 0; } }

@keyframes scaleFadeIn {
  from {
    transform: scale(1.02);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

.capitalize {
  text-transform: capitalize; }

.features-list .edit,
.features-list .normal {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .features-list .edit .icons,
  .features-list .normal .icons {
    display: flex;
    flex-direction: column-reverse; }
  .features-list .edit i,
  .features-list .edit svg,
  .features-list .normal i,
  .features-list .normal svg {
    cursor: pointer;
    font-size: 16px;
    margin: 10px; }
  .features-list .edit input,
  .features-list .normal input {
    width: 50px; }

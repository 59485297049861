.data-overview {
  .box-title {
    font-weight: bold;
  }
  .items {
    display: flex;
    .data-box {
      width: 220px;
      height: 220px;
      background-color: white;
      position: relative;
      margin: 15px;
      border: 1px solid #f0f0f1;
      .count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #2d2e2c;
        font-size: 32px;
      }

      .type {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: #9ea0a5;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}

.chart {
  margin: 50px 0;
}

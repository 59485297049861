.create-admin {
  width: 60%;
  margin: 0 auto; }
  .create-admin > div {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .create-admin > div .create-piece__btn-container {
      width: 100%; }
    .create-admin > div input[type='checkbox'] {
      width: 24px;
      height: 16px;
      margin: 0; }
  .create-admin .categories > div {
    display: flex;
    align-items: center; }
    .create-admin .categories > div label {
      margin: 5px; }
  .create-admin .table th,
  .create-admin .table td {
    padding: 6px; }
  .create-admin .systemPassword .label {
    color: #3199c2;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px; }
    .create-admin .systemPassword .label:hover {
      color: #5dabca;
      text-decoration: underline; }
    .create-admin .systemPassword .label:active {
      color: #5193ad;
      text-decoration: underline; }
  .create-admin .systemPassword .randPass {
    color: #d80707;
    margin-left: 5px; }
  .create-admin .changePass {
    color: #3199c2;
    cursor: pointer;
    text-align: right; }
    .create-admin .changePass:hover {
      color: #5dabca;
      text-decoration: underline; }
